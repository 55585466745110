import logo from "./logo.svg";
import "./App.css";
import { ForceGraph3D } from "react-force-graph";
import { useRef, useCallback, useEffect, useState, useMemo } from "react";
import SpriteText from "three-spritetext";
import Graph from "./Graph";
import GraphComp from "./GraphComp";
import GraphComp3D from "./GraphComp3D";
import GraphCompAR from "./GraphCompAR";
import EdgeType from "./EdgeType";
import Architect from "./Architect";
import App2 from "./App2";
import App3 from "./App3";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RestHelper from "./helpers/RestHelper";
import Login from "./Login";

function App() {
  const [user, setUser] = useState();

  useEffect(() => {
    getUser();
  }, []);

  const getUser = (callback) => {
    RestHelper.fetchAuthenticated({
      url: "/users/getUser",
      body: {},
      cb: (result) => {
        const { user } = result;
        setUser(user);
      },
    });
  };

  const main = () => (
    <BrowserRouter>
      <Routes>
        <Route index element={<Architect />} />
        <Route path="app2" element={<App2 />} />
        <Route path="app3" element={<App3 />} />
      </Routes>
    </BrowserRouter>
  );

  return <>{!user ? <Login getUser={getUser}></Login> : main()}</>;
}

export default App;
